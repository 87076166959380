<template>
  <v-card class="accounting-document d-flex flex-column" style="height: 100%;">
    <v-toolbar color="grey darken-3" dark elevation="1">
      <v-toolbar-title>
        {{ title }}
      </v-toolbar-title>
      <v-spacer></v-spacer>
      <v-menu offset-y v-model="menu">
        <template v-slot:activator="{ on, attrs }">
          <v-btn
              class="grey darken-3"
              icon
              outlined
              v-bind="attrs"
              v-on="on"
              :loading="getModalLoading"
              :disabled="isNew"
              @click="menu = true"
          >
            <v-icon color="secondary">mdi-arrow-down-drop-circle-outline</v-icon>
          </v-btn>
        </template>
        <v-list dense rounded nav>
          <v-list-item link class="px-4" @click.stop="deleteDialog">
            <v-list-item-icon>
              <v-icon color="error lighten-1">mdi-delete-forever-outline</v-icon>
            </v-list-item-icon>
            <v-list-item-title class="font-weight-medium">Вилучити</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
    </v-toolbar>
    <v-navigation-drawer
        right
        app
        width="400"
        v-model="document_state.show"
        style="z-index: 9999"
        color="grey lighten-4"
    >
      <v-card tile flat class="transparent">
        <v-card-text class="pa-0">
          <div style="display: flex; align-items: center" class="py-2 pl-3 pr-1 grey darken-2 white--text">
            <div style="flex: 1; font-weight: 500;">Перелік помилок заповнення</div>
            <div style="flex: 0 0 30px">
              <v-btn icon small @click.stop="document_state.show = false" class=" grey darken-2">
                <v-icon color="secondary">mdi-close</v-icon>
              </v-btn>
            </div>
          </div>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-text class="px-2 pt-2 pb-2">
          <div>
            <div style="display: flex; margin-bottom: 5px; padding: 3px; border-radius: 5px;"
                 class="elevation-3 white"
                 v-for="(item, item_idx) in document_state.errors"
                 :key="`error-${item_idx}`"
            >
              <div class="py-1 px-2">
                <div class="mb-1">
                  <v-chip small color="success" label class="mr-1" style="height: 20px !important;">
                    {{ item.table }}
                  </v-chip>
                  <v-chip small color="grey lighten-3" label class="mr-1" style="height: 20px !important;"
                          v-if="item.row_num">
                    {{ `№ рядка: ${item.row_num}` }}
                  </v-chip>
                </div>
                <div style="color: #2d2d2d; font-weight: 400; font-size: .76rem">
                  {{ item.text }}
                </div>
              </div>
            </div>
          </div>
        </v-card-text>
      </v-card>
    </v-navigation-drawer>
    <v-card-text class="pt-0 pb-0 px-0"
                 style="flex: 1; display: flex; flex-direction: column; overflow: hidden;">
      <div class="document-body pt-0 pb-1" style="flex: 1; display: flex; flex-direction: column; overflow: hidden;">
        <div class="px-4 pt-2 pb-0" style="flex: 1; display: flex; height: 100%;">
          <v-row style="height: 100%;">
            <v-col cols="12" sm="12" md="12" style="overflow: hidden; height: 100%;">
              <v-tabs class="custom-tabs without" color="success" style="height: 100%; " v-model="tab">
                <v-tab>
                  Основне
                </v-tab>
                <v-tab v-if="document_type === 'pretense'">
                  Стан претензії
                </v-tab>
                <v-tab v-if="document_type === 'court_case'">
                  Стан судової справи
                </v-tab>

                <v-tab-item class="pa-0 pt-3" style="height: 100%;">
                  <v-row>
                    <v-col cols="4">
                      <v-select v-model="document_type"
                                label="Вид документа"
                                :items="court_case_table_type_select"
                                hide-details filled color="grey"
                                required :rules="[v => !!v || 'Це поле є обов’язковим']"
                                :class="document_type ? '' : 'req-star'"
                                @change="onDocumentTypeChange"
                      />
                    </v-col>
                    <v-col cols="4">
                      <date-component filled v-model="date"
                                      :class_="!date ? 'req-star' : ''"
                                      :first_day_of_month="true"
                                      label="Дата"/>
                    </v-col>
                    <v-col cols="4"></v-col>
                    <v-col cols="4">
                      <date-component filled v-model="balance_month"
                                      :class_="!balance_month ? 'req-star' : ''"
                                      :first_day_of_month="true"
                                      req @afterSaveDate="onBalanceMonthChange"
                                      label="Місяць боргу"/>
                    </v-col>
                    <v-col cols="4">
                      <v-text-field v-model="balance_sum" filled hide-details color="grey"
                                    label="Сума боргу" v-decimal
                                    :class="!balance_sum ? 'req-star' : ''" />
                    </v-col>
                    <v-col cols="4" />
                    <v-col cols="4" v-if="document_type === 'pretense'">
                      <v-text-field label="Надавач довідки" filled color="grey" v-model="certificate_owner" hide-details/>
                    </v-col>
                    <v-col cols="4" v-if="document_type === 'pretense'">
                      <date-component filled v-model="certificate_date"
                                      label="Дата довідки"/>
                    </v-col>
                    <v-col cols="2" v-if="document_type === 'pretense'">
                      <v-text-field label="№ довідки" filled color="grey" v-model="certificate_number" hide-details/>
                    </v-col>
                    <v-col cols="2" v-if="document_type === 'pretense'">
                      <v-text-field label="К-сть зареєстр." filled color="grey" v-integer v-model="certificate_value" hide-details/>
                    </v-col>
                    <template v-if="document_type === 'court_case'">
                      <v-col cols="6" md="4" class="pb-1">
                        <v-text-field v-model="court_fee" filled hide-details color="grey"
                                      label="Судовий збір" v-decimal
                        />
                      </v-col>

                      <v-col cols="6" md="4" class="pb-1">
                        <v-text-field v-model="litigation_costs" filled hide-details color="grey"
                                      label="Судові витрати" v-decimal
                        />
                      </v-col>
                    </template>

                    <v-col cols="12" md="8">
                      <v-textarea v-model="notes" filled hide-details
                                  label="Нотатки"
                                  color="grey" auto-grow rows="3"
                      />
                    </v-col>

                    <v-col cols="4">
                      <div>
                        <v-switch v-model="its_closed" :label="its_closed ? 'Документ закритий' : 'Документ актуальний'" hide-details class="pb-3 pt-0 mt-2"
                                    color="success" :ripple="false"/>
                      </div>
                      <div>
                        <date-component filled v-model="close_date"
                                        :disabled="!its_closed"
                                        :class_="its_closed && !close_date ? 'req-star' : ''"
                                        label="Дата закриття"/>
                      </div>
                    </v-col>

                  </v-row>
                </v-tab-item>
                <v-tab-item class="pa-0 pt-3" style="height: 100%;" v-if="document_type === 'pretense'">
                  <div class="command-panel mt-2">
                    <v-btn small
                           :dark="document_state.header_ready"
                           class="success mr-2" :disabled="!document_state.header_ready"
                           @click.stop="addPretenseDetailRow"
                    >
                      <v-icon small left>mdi-plus</v-icon>
                      Додати рядок
                    </v-btn>
                    <v-btn small
                           :dark="document_state.header_ready"
                           class="error lighten-1" :disabled="!document_state.header_ready"
                           @click.stop="clearDialogPretenseDetails"
                    >
                      <v-icon small left>mdi-close</v-icon>
                      Очистити таблицю
                    </v-btn>
                  </div>

                  <div class="document-table with-command mt-2">
                    <div class="document-table-body">
                      <div class="document-table-header">
                        <div class="document-table-row">
                          <div class="document-table-col" style="flex: 0 0 70px">
                            № п/п
                          </div>
                          <div class="document-table-col" style="flex: 0 0 110px">
                            Дата
                          </div>
                          <div class="document-table-col" style="flex: 0 0 270px">
                            Статус
                          </div>
                          <div class="document-table-col" style="flex: 1">
                            Нотатки
                          </div>
                        </div>
                      </div>
                      <div class="document-table-row"
                           v-for="(charge) in pretense_details"
                           :key="`charge-${charge.row_num}`"
                      >
                        <div class="document-table-col" style="flex: 0 0 70px; display: flex">
                          <div style="flex: 1; padding-left: 4px">
                            {{ pretense_details.indexOf(charge) + 1 }}
                          </div>
                          <div style="flex:  0 0 32px">
                            <v-btn icon small class="grey lighten-3" @click="deletePretenseDetailRow(charge)">
                              <v-icon small color="grey darken-2">mdi-delete</v-icon>
                            </v-btn>
                          </div>
                        </div>
                        <div class="document-table-col" style="flex: 0 0 110px; display: flex">
                          <date_input :row_num="charge.row_num" v-model="charge.date"
                                      :required="true" small transparent/>
                        </div>
                        <div class="document-table-col" style="flex: 0 0 270px; display: flex">
                          <select_input
                              v-model="charge.status"
                              :import-getter="{ name: 'pretense_status_select' }"
                              :show-select-on-focus="true"
                              :input-value-as-value="false"
                              :show-on-focus="false"
                              small transparent :required="true"
                          />
                        </div>
                        <div class="document-table-col" style="flex: 1; display: flex">
                          <string_input :row_num="charge.row_num" v-model="charge.comment"
                                        :required="false" small transparent/>
                        </div>
                      </div>
                    </div>
                  </div>

                </v-tab-item>
                <v-tab-item class="pa-0 pt-3" style="height: 100%;" v-if="document_type === 'court_case'">
                  <div class="command-panel mt-2">
                    <v-btn small
                           :dark="document_state.header_ready"
                           class="success mr-2" :disabled="!document_state.header_ready"
                           @click.stop="addCourtCaseDetailRow"
                    >
                      <v-icon small left>mdi-plus</v-icon>
                      Додати рядок
                    </v-btn>
                    <v-btn small
                           :dark="document_state.header_ready"
                           class="error lighten-1" :disabled="!document_state.header_ready"
                           @click.stop="clearDialogCourtCaseDetails"
                    >
                      <v-icon small left>mdi-close</v-icon>
                      Очистити таблицю
                    </v-btn>
                  </div>

                  <div class="document-table with-command mt-2">
                    <div class="document-table-body">
                      <div class="document-table-header">
                        <div class="document-table-row">
                          <div class="document-table-col" style="flex: 0 0 70px">
                            № п/п
                          </div>
                          <div class="document-table-col" style="flex: 0 0 110px">
                            Дата
                          </div>
                          <div class="document-table-col" style="flex: 0 0 250px">
                            Документ
                          </div>
                          <div class="document-table-col" style="flex: 0 0 200px">
                            Статус
                          </div>
                          <div class="document-table-col" style="flex: 1">
                            Нотатки
                          </div>
                        </div>
                      </div>
                      <div class="document-table-row"
                           v-for="(charge) in court_case_details"
                           :key="`charge-${charge.row_num}`"
                      >
                        <div class="document-table-col" style="flex: 0 0 70px; display: flex">
                          <div style="flex: 1; padding-left: 4px">
                            {{ court_case_details.indexOf(charge) + 1 }}
                          </div>
                          <div style="flex:  0 0 32px">
                            <v-btn icon small class="grey lighten-3" @click="deleteCourtCaseDetailRow(charge)">
                              <v-icon small color="grey darken-2">mdi-delete</v-icon>
                            </v-btn>
                          </div>
                        </div>
                        <div class="document-table-col" style="flex: 0 0 110px; display: flex">
                          <date_input :row_num="charge.row_num" v-model="charge.date"
                                      :required="true" small transparent/>
                        </div>
                        <div class="document-table-col" style="flex: 0 0 250px; display: flex">
                          <select_input
                              v-model="charge.document"
                              :import-getter="{ name: 'court_case_document_type_select' }"
                              :show-select-on-focus="true"
                              :input-value-as-value="false"
                              clearable small transparent :required="true"
                          />
                        </div>
                        <div class="document-table-col" style="flex: 0 0 200px; display: flex">
                          <select_input
                              v-model="charge.status"
                              :import-getter="{ name: 'court_case_stage_of_enforcement_type_select' }"
                              :show-select-on-focus="true"
                              :input-value-as-value="false"
                              clearable small transparent :required="true"
                          />
                        </div>
                        <div class="document-table-col" style="flex: 1; display: flex">
                          <string_input :row_num="charge.row_num" v-model="charge.comment"
                                        :required="false" small transparent/>
                        </div>
                      </div>
                    </div>
                  </div>

                </v-tab-item>
              </v-tabs>
            </v-col>
          </v-row>
        </div>
      </div>
    </v-card-text>
    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn depressed text color="secondary darken-1" @click="crud_item" :loading="getModalLoading"
             class="button-accept">
        <v-icon left>mdi-content-save</v-icon>
        Зберегти
      </v-btn>
      <v-btn depressed text tile color="grey" @click="closeModal">
        <v-icon left>mdi-close</v-icon>
        Відмінити
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import flatCourtCaseAPI from "@/utils/axios/flat_court_case"

import {ALERT_SHOW} from "@/store/actions/alert";
import {QUESTION_SHOW} from "@/store/actions/question";

import ModalComponentMixin from "@/mixins/modal_component";
import {
  court_case_document_type_select,
  court_case_stage_of_enforcement_type_select,
  court_case_resolution_type_select,
  court_case_table_type_select,
    getCurrentDate
} from "@/utils/icons"
import {mapGetters} from "vuex";
import {maxBy} from "lodash";

const modalDeleteId = 'court_case_modal_delete'

export default {
  name: "HWP_Modal_Flat_CourtCase",
  mixins: [ModalComponentMixin],
  components: {
    date_input: () => import("@/components/accounting/input/document_date_input"),
    select_input: () => import("@/components/accounting/input/document_select_input"),
    string_input: () => import("@/components/accounting/input/document_string_input"),
  },
  computed: {
    ...mapGetters({
      'current_month': 'getCurrentMonth'
    })
  },
  data() {
    return {
      flat_id: this.item.flat_id,
      document_type: this.item.document_type,
      court_fee: this.item.court_fee,
      litigation_costs: this.item.litigation_costs,
      balance_month: this.item.balance_month,
      balance_sum: this.item.balance_sum,
      its_closed: this.item.its_closed,
      close_date: this.item.close_date || null,
      notes: this.item.notes,
      date: this.item.date,
      certificate_owner: this.item.certificate_owner,
      certificate_date: this.item.certificate_date,
      certificate_number: this.item.certificate_number,
      certificate_value: this.item.certificate_value,
      court_case_document_type_select,
      court_case_stage_of_enforcement_type_select,
      court_case_resolution_type_select,
      court_case_table_type_select,
      tab: 0,
      document_state: {
        ready: false,
        header_ready: false,
        show: false,
        errors: []
      },
      pretense_details: [],
      court_case_details: [],
    }
  },
  methods: {
    closeModal(payload = null) {
      this.$emit('closeModal', payload)
      this.itemId = this.item.id
      this.flat_id = this.item.flat_id
      this.document_type = this.item.document_type
      this.court_fee = this.item.court_fee
      this.litigation_costs = this.item.litigation_costs
      this.balance_month = this.item.balance_month
      this.balance_sum = this.item.balance_sum
      this.its_closed = this.item.its_closed
      this.close_date = this.item.close_date || null
      this.date = this.item.date
      this.certificate_owner = this.item.certificate_owner
      this.certificate_date = this.item.certificate_date
      this.certificate_number = this.item.certificate_number
      this.certificate_value = this.item.certificate_value
      this.notes = this.item.notes
      this.pretense_details = []
      this.court_case_details = []
    },
    deleteDialog() {
      this.menu = false
      const payload = {
        text: 'Підтвердіть вилучення претензії/судової справи',
        accept_button: true,
        id: modalDeleteId
      }
      this.$store.dispatch(QUESTION_SHOW, payload)
    },
    onDocumentTypeChange(payload) {
      this.$nextTick(() => {
        if (payload === 'pretense') {
          if (!this.pretense_details.length) {
            this.addPretenseDetailRow()
          }
        }
        if (payload === 'court_case') {
          if (!this.court_case_details.length) {
            this.addCourtCaseDetailRow()
          }
        }

        this.checkDocument(false, false)
      })
    },
    checkDocumentTable(filters, table_name, table) {
      const table_charge_error = []
      const items = this[table].filter(item => {
        let r = false
        Object.keys(filters).forEach(key => {
          if (item[key] === undefined || item[key] === null || item[key] === 0) {
            r = true
          }
        })
        return r
      })

      items.forEach(item => {
        const idx = this[table].indexOf(item)
        const row_key = `row_${idx}`

        Object.keys(filters).forEach(key => {
          const filter_row = filters[key]
          let skip = false

          if (filter_row.condition) {
            if (item[filter_row.condition] !== null && item[filter_row.condition] !== undefined && item[filter_row.condition] !== 0) {
              skip = item[key] !== null && item[key] !== undefined && item[key] !== 0
            } else {
              skip = true
            }
          } else {
            skip = item[key] !== null && item[key] !== undefined && item[key] !== 0;
          }

          if (!skip) {
            if (!table_charge_error[row_key]) {
              table_charge_error[row_key] = {row_num: idx + 1, text: filters[key].text}
            } else {
              table_charge_error[row_key].text += `// ${filters[key].text}`
            }
          }
        })
      })

      Object.keys(table_charge_error).forEach(key => {
        this.document_state.errors.push(
            {table: table_name, row_num: table_charge_error[key].row_num, text: table_charge_error[key].text}
        )
      })
    },
    checkDocument(show=false, table=true) {
      this.document_state.show = false
      this.document_state.errors = []

      const header = {
        'document_type': {id: 'document_type', text: 'Не заповнений тип документу'},
        'balance_month': {id: 'balance_month', text: 'Не заповнений місяць заборгованості'},
      }

      const pretense_details = {
        'date': {id: 'date', table: 'Статус претензії', text: 'Не заповнена дата'},
        'status': {id: 'status', table: 'Статус претензії', text: 'Не заповнений статус'},
      }

      const court_case_details = {
        'date': {id: 'date', table: 'Статус судової справи', text: 'Не заповнена дата'},
        'document': {id: 'document', table: 'Статус судової справи', text: 'Не заповнений вид документу'},
        'status': {id: 'status', table: 'Статус судової справи', text: 'Не заповнений статус'},
      }

      Object.keys(header).forEach(key => {
        if (!this[key]) {
          this.document_state.errors.push(
              {table: 'Основне', row_num: 0, text: header[key].text}
          )
        }
      })

      this.document_state.header_ready = this.document_state.errors.length === 0;

      if (table) {
        if (this.document_type === 'pretense') {
          this.checkDocumentTable(pretense_details, 'Статус претензії', 'pretense_details')
        }
        if (this.document_type === 'court_case') {
          this.checkDocumentTable(court_case_details, 'Статус судової справи', 'court_case_details')
        }
      }

      if (this.document_state.errors.length) {
        this.document_state.ready = false
        this.document_state.show = show
      } else {
        this.document_state.ready = true
        this.document_state.show = false
      }

    },
    crud_item() {
      this.checkDocument()
      if (!this.document_state.ready) {
        this.document_state.show = true
        return
      }

      const payload = {
        flat_id: this.flat_id,
        document_type: this.document_type,
        court_fee: this.court_fee,
        litigation_costs: this.litigation_costs,
        balance_month: this.balance_month,
        balance_sum: this.balance_sum,
        its_closed: this.its_closed,
        close_date: this.close_date || null,
        date: this.date,
        certificate_owner: this.item.certificate_owner,
        certificate_date: this.item.certificate_date,
        certificate_number: this.item.certificate_number,
        certificate_value: this.item.certificate_value,
        notes: this.notes,
        pretense_details: this.pretense_details,
        court_case_details: this.court_case_details
      }

      if (this.isNew) {
        flatCourtCaseAPI.create(payload)
            .then(response => response.data)
            .then(() => {
              this.closeModal('fetch')
            })
      } else {
        payload.id = this.itemId
        flatCourtCaseAPI.update(payload)
            .then(response => response.data)
            .then(() => {
              this.closeModal('fetch')
            })
      }
    },
    fetch_data_by_id() {
      if (!this.isNew) {
        flatCourtCaseAPI.get_document_by_id(this.itemId)
            .then(response => response.data)
            .then(data => {
              this.tab = 0

              this.pretense_details = data.pretense_details.map((i, idx) => {
                return Object.assign({}, i, {row_num: idx + 1})
              })
              this.court_case_details = data.court_case_details.map((i, idx) => {
                return Object.assign({}, i, {row_num: idx + 1})
              })

              this.$nextTick(() => {
                this.checkDocument(false, true)
              })
            })
      }
    },
    onBalanceMonthChange(balance_month) {
      this.$nextTick(() => {
        if (balance_month) {
          const payload = { flat_id: this.flat_id, month: balance_month }
          flatCourtCaseAPI.get_balance(payload)
              .then(response => response.data)
              .then(data => {
                this.balance_sum = data
              })
              .catch(err => {
                const error = err.response.data.detail;
                this.$store.commit(ALERT_SHOW, { text: error, color: 'error lighten-1' })
              });
        } else {
          this.balance_sum = 0
        }
      })
    },
    getNewRowNum(table) {
      const max_row_item = maxBy(this[table], 'row_num')

      let max_row_num = 1
      if (max_row_item) {
        max_row_num = max_row_item.row_num + 1
      }
      return max_row_num
    },
    addPretenseDetailRow() {
      let pretense_status = null

      if (!this.pretense_details.length) {
        pretense_status = 'pretense_create'
      }

      this.pretense_details.push(
          {
            flat_id: this.flat_id,
            date: getCurrentDate(),
            status: pretense_status,
            row_num: this.getNewRowNum('pretense_details'),
          }
      )
    },
    addCourtCaseDetailRow() {
      let court_case_document = null
      let court_case_status = null

      if (!this.court_case_details.length) {
        court_case_document = 'statement_of_claim'
        court_case_status = 'open'
      }

      this.court_case_details.push(
          {
            flat_id: this.flat_id,
            date: getCurrentDate(),
            status: court_case_status,
            document: court_case_document,
            row_num: this.getNewRowNum('court_case_details'),
          }
      )
    },
    deletePretenseDetailRow(payload) {
      this.pretense_details.splice(this.pretense_details.indexOf(payload), 1)
    },
    deleteCourtCaseDetailRow(payload) {
      this.court_case_details.splice(this.court_case_details.indexOf(payload), 1)
    },
    clearPretenseDetails() {
      this.pretense_details = []
    },
    clearCourtCaseDetails() {
      this.court_case_details = []
    },
    clearDialogPretenseDetails() {
      const payload = {
        text: `Очистити таблицю статусів претензії?`,
        accept_button: true,
        id: 'clear_pretense_details'
      }
      this.$store.dispatch(QUESTION_SHOW, payload)
    },
    clearDialogCourtCaseDetails() {
      const payload = {
        text: `Очистити таблицю статусів судової справи?`,
        accept_button: true,
        id: 'clear_court_case_details'
      }
      this.$store.dispatch(QUESTION_SHOW, payload)
    },
    watch_item() {
      this.watcher = this.$watch(
          'item',
          {
            deep: true,
            immediate: true,
            handler(payload) {
              this.flat_id = payload.flat_id
              this.document_type = payload.document_type
              this.court_fee = payload.court_fee
              this.litigation_costs = payload.litigation_costs
              this.balance_month = payload.balance_month
              this.balance_sum = payload.balance_sum
              this.its_closed = payload.its_closed
              this.close_date = payload.close_date || null
              this.notes = payload.notes
              this.itemId = payload.id
              this.date = payload.date
              this.certificate_owner = payload.certificate_owner
              this.certificate_number = payload.certificate_number
              this.certificate_date = payload.certificate_date
              this.certificate_value = payload.certificate_value
              this.pretense_details = []
              this.court_case_details = []
              this.tab = 0

              if (this.isNew && this.document_type) {
                if (this.document_type === 'pretense') {
                  this.addPretenseDetailRow()
                } else {
                  this.addCourtCaseDetailRow()
                }
              }

              this.fetch_data_by_id()

              if (this.isNew && this.current_month) {
                const payload = { flat_id: this.flat_id, month: this.current_month }
                flatCourtCaseAPI.get_balance(payload)
                    .then(response => response.data)
                    .then(data => {
                      this.balance_month = this.current_month
                      this.balance_sum = data
                    })
                    .catch(err => {
                      const error = err.response.data.detail;
                      this.$store.commit(ALERT_SHOW, { text: error, color: 'error lighten-1' })
                    })
                    .finally(() => {
                      this.checkDocument(false, false)
                    })
              } else {
                this.checkDocument(false, false)
              }
            }
          }
      )
    },
    watch_modal_answer() {
      this.delete_watcher = this.$watch(
          'modalAnswer',
          {
            handler(payload) {
              if (payload.id === modalDeleteId) {
                if (payload.answer) {
                  flatCourtCaseAPI.remove(this.itemId)
                      .then(response => response.data)
                      .then(data => {
                        if (data) {
                          this.closeModal('fetch')
                        }
                      })
                }
              }
              if (payload.id === 'clear_pretense_details') {
                if (payload.answer) {
                  this.clearPretenseDetails()
                }
              }
              if (payload.id === 'clear_court_case_details') {
                if (payload.answer) {
                  this.clearCourtCaseDetails()
                }
              }
            }
          }
      )
    },
    watch_dialog() {
      this.dialog_watcher = this.$watch(
          'dialog',
          {
            immediate: true,
            handler(payload) {
              if (payload) {
                this.watch_item()
                this.watch_modal_answer()
              } else {
                if (this.watcher) {
                  this.watcher()
                }
                if (this.delete_watcher) {
                  this.delete_watcher()
                }
              }
            }
          }
      )
    }
  }
}
</script>

<style lang="scss">
  .section-title {
    font-size: .92rem;
    font-weight: 500;
    padding-left: 6px;
    padding-top: 4px !important;
    padding-bottom: 6px !important;
    color: var(--v-success-base);
    position: relative;

    &::after {
      display: block;
      width: 100%;
      content: '';
      border-bottom: 1px solid #eaeaea;
    }
  }
</style>
